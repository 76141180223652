@import '../../website_theme/scss/variables';

.image-overlay-button {
  position: absolute;
  right: 0;
  width: 7%;
  opacity: .4;
  height: 100%;
  text-align: center;
  background-color: $primary;
}
.image-card-row:hover + .image-overlay-button {
  opacity: .9 !important;
}
.image-card-row div a {
  z-index: 1;
}
.image-overlay-button-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  white-space: nowrap;
}

@media screen and (min-width: 1201px) {
  .image-overlay-button-inner {
    font-size: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .image-overlay-button-inner {
    font-size: 2vw;
  }
}
