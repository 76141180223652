$starColour:      #fec701;
$starHoverColour: #f3ba00;
$starGrayColour:  #969696;

.smiley-rating {
  font-family: 'Font Awesome 5 Free';
  > .stars {
    border: none;
    display: inline-block;

    &:not(:checked) {
      > input {
        position: absolute;
        clip: rect(0,0,0,0);
      }

      > label {
        float: right;
        width: 1.1em;
        //padding: 0 .05em;
        margin-right: 0.35rem;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        font-size: 1.25125rem;
        color: $starGrayColour;

        &:before {
          content: '\f118  ';
          font-weight: 200;
        }

        &:hover,
        &:hover ~ label {
          color: $starHoverColour;
          text-shadow: 0 0 3px $starHoverColour;
          &:before {
            content: '\f118  ';
            font-weight: 200;
          }
        }
      }
    }

    > input:checked {
      & ~ label {
        &:before {
          content: '\f118  ';
          font-weight: 200;
          color: $starColour;

        }
      }
    }

    > label:active {
      position: relative;
      top: 2px;
      color: $starColour;

    }
  }
}
