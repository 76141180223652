@use 'variables';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

/* Custom Scss */
.coming-soon {
  background: no-repeat center top fixed;
  background-size: cover;
  .coming-soon-logo {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 15px 0;
    text-align: center;
    background-color: rgba(variables.$black, 0.3);
    @include media-breakpoint-up(md) {
      padding: 32px 0;
    }
    img {
      max-width: 100%;
      outline: 0;
    }
  }
  .coming-soon-content {
    text-align: center;
    h1 {
      font-size: 30px;
      color:  $white;
      margin-bottom: 20px;
      text-transform: uppercase;
      @include media-breakpoint-up(md) {
        font-size: 40px;
        margin-bottom: 40px;
      }
    }
    p {
      font-size: 0.875rem;
      color: #a7a7a7;
      margin-bottom: 30px;
      @include media-breakpoint-up(md) {
        // font-size: 1.5rem;
      }
    }
  }
  .coming-soon-count {
    margin-bottom: 35px;
  }
}

/* Soyotimer */
.coming-soon-count {
  .timer-body-block {
    display: flex;
    justify-content: center;
    text-align: center;
    .table-cell {
      margin: 0 4px;
      @include media-breakpoint-up(md) {
        margin: 0 12px;
      }
      @include media-breakpoint-up(xl) {
        margin: 0 15px;
      }
      .tab-val {
        display: block;
        width: 65px;
        height: 65px;
        line-height: 65px;
        margin-bottom: 20px;
        font-size: 1.5625rem;
        border-radius: 50%;
        color:  $white;
        font-weight: 700;
        border: 1px solid  $white;
        background-color: rgba( $white, 0.1);
        @include media-breakpoint-up(md) {
          width: 85px;
          height: 85px;
          line-height: 85px;
          font-size: 2.1875rem;
          margin-bottom: 20px;
        }
        @include media-breakpoint-up(xl) {
          width: 120px;
          height: 120px;
          line-height: 120px;
        }
      }
      .tab-unit {
        font-size: 12px;
        text-transform: uppercase;
        color: #a7a7a7;
        // margin: -10px;
        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }
    }
  }
}