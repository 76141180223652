/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/monteserrat/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/monteserrat/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/monteserrat/montserrat-v25-latin-regular.woff') format('woff'); /* Modern Browsers */
    font-display: swap;
}
/* montserrat-500 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/monteserrat/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/monteserrat/montserrat-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/monteserrat/montserrat-v25-latin-500.woff') format('woff'); /* Modern Browsers */
    font-display: swap;
}
/* montserrat-600 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/monteserrat/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/monteserrat/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/monteserrat/montserrat-v25-latin-600.woff') format('woff'); /* Modern Browsers */
    font-display: swap;
}
/* montserrat-700 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/monteserrat/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/monteserrat/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/monteserrat/montserrat-v25-latin-700.woff') format('woff'); /* Modern Browsers */
    font-display: swap;
}
/* montserrat-italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/monteserrat/montserrat-v25-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/monteserrat/montserrat-v25-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/monteserrat/montserrat-v25-latin-italic.woff') format('woff'); /* Modern Browsers */
    font-display: swap;
}