/* Opacity With background */
.opacity-5 {
  opacity: .5;
}

.opacity-7 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-8 {
  opacity: .8;
}

.opacity-85 {
  opacity: .85;
}