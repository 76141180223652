@use '../variables' as variables;

// Custom Hover
.hover-bg-primary {
  transition: all .3s ease-in-out;
  &:hover {
    background-color: variables.$primary;
    border-color: variables.$primary;
    color:  $white;
  }
}

.hover-bg-light {
  transition: all .3s ease-in-out;
  &:hover {
    background-color: $light;
  }
}

.hover-bg-success {
  transition: all .3s ease-in-out;
  &:hover {
    background-color: variables.$success;
    border-color: variables.$success;
    color:  $white;
  }
}

.hover-bg-danger {
  transition: all .3s ease-in-out;
  &:hover {
    background-color: variables.$danger;
    border-color: variables.$danger;
    color: $white;
  }
}

.hover-bg-warning {
  transition: all .3s ease-in-out;
  &:hover {
    background-color: variables.$warning;
    border-color: variables.$warning;
    color: $white;
  }
}

.hover-bg-info {
  transition: all .3s ease-in-out;
  &:hover {
    background-color: variables.$info;
    border-color: variables.$info;
    color: $white;
  }
}

.hover-overlay {
  transition: all .3s ease-in-out;
  &:hover {
    background-color: rgba(variables.$dark, .5);
  }
}

.hover-img-overlay-dark {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  &:hover {
    background-color: rgba(variables.$dark, .3);
  }
}

a.hover-text-primary {
  &:hover, &:focus {
    color: variables.$primary !important;
  } 
}

a.hover-text-success {
  &:hover, &:focus {
    color: variables.$success !important;
  } 
}

a.hover-text-danger {
  &:hover, &:focus {
    color: variables.$danger !important;
  } 
}

a.hover-text-info {
  &:hover, &:focus {
    color: variables.$info !important;
  } 
}

a.hover-text-warning {
  &:hover, &:focus {
    color: variables.$warning !important;
  } 
}

a.hover-text-success-light {
  &:hover, &:focus {
    color: variables.$success-light !important;
  } 
}

a.hover-text-gray-color {
  &:hover, &:focus {
    color: variables.$gray-color !important;
  } 
}

.hover-profile {
  &:hover {
    .icon-setting {
      opacity: 1;
    }
  }
}