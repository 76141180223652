@use '../variables' as variables;

/* Border Width Custom */
.border-2 {
  border-width: 2px !important;
}
.border-3 {
  border-width: 3px !important;
}
.border-4 {
  border-width: 4px !important;
}
.border-5 {
  border-width: 5px !important;
}
.border-6 {
  border-width: 6px !important;
}
.border-7 {
  border-width: 7px !important;
}
.border-15 {
  border-width: 15px !important;
}

.border-top-5 {
  border-width: 5px !important;
}

/* dashed Width Custom */
.dashed {
  border: 2px dashed;
}
.border-gray {
  border-color:  $border-color !important;
}
.dashed-top {
  border-top: 2px dashed;
}
.dashed-bottom {
  border-bottom: 2px dashed;
}
.dashed-left {
  border-left: 2px dashed;
}
.dashed-right {
  border-right: 2px dashed;
}

/* Custom Border-color */
.border-primary-light {
  border-color: rgba(variables.$primary, 0.3) !important;
}

.rounded-top-0 {
  border-radius: 0 0 variables.$border-radius variables.$border-radius !important;
}