.fixed-bubble {
    display: none;
    border: #ffc734 solid 1px;
    background-color: #ffc734;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1001;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: -7px 10px 13px -3px rgb(0 0 0 / 57%);
    opacity: 80%;
    height: 43px;
}
.fixed-bubble:hover {
    opacity: 100%;
}
.fixed-bubble:before {
    z-index: 1000;
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-right: 30px solid #ffc734;
    border-left: 12px solid transparent;
    border-bottom-left-radius: 10px;
    border-bottom: 20px solid transparent;
    right: -1px;
    bottom: -12px;
}