@use 'variables';

/** Custom  Modal **/
.modal-sm {
  .modal-content {
    border-radius: 6px;
    .modal-header {
      border-top: 6px solid variables.$primary;
      border-bottom: 0;
      padding: 10px 20px;
      .close {
        font-size: 30px;
        color: variables.$dark;
        opacity: 1;
        font-weight: 400;
        padding: 0;
        margin: 0;
        &:focus {
          outline: 0;
        }
      }
    }
    .modal-body {
      padding: 10px 20px;
    }
    .modal-footer {
      border-top: 0;
    }
  }
}