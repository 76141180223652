@use 'colors';

.text-option {
  color: colors.$color-option;
}

.btn-option {
  border-color: rgba(0,0,0,0);
  background: colors.$color-option;
  color: #fff;
  font-weight: 500;
}

.btn-option:hover {
  border-color: rgba(0,0,0,0);
  background: colors.$color-option-dark;
  color: #fff;
  font-weight: 500;
}

.btn-outline-option {
  color: colors.$color-option-dark;
  border-color: colors.$color-option-dark;
  font-weight: 500;
}

.btn-outline-option:hover {
  color: #fff;
  background-color: colors.$color-option-dark;
  border-color: colors.$color-option-dark;
}

.btn-outline-option:focus, .btn-outline-option.focus {
  box-shadow: 0 0 0 .2rem colors.$color-option-dark;
}

.btn-outline-option.disabled, .btn-outline-option:disabled {
  color: colors.$color-option-dark;
  background-color: transparent;
}

.btn-va {
  border-color: #75a2a6 !important;
  background: colors.$color-va-light;
}

.btn-va:hover {
  border-color: #75a2a6 !important;
  background: colors.$color-va-dark;
}

.btn-angebot {
  border-color: rgba(0,0,0,0);
  background: colors.$color-angebot-dark;
  color: #fff;
}

.btn-outline-angebot {
  color: colors.$color-angebot-dark;
  border-color: colors.$color-angebot-dark;
}

.btn-outline-angebot:hover {
  color: #fff;
  background-color: colors.$color-angebot-dark;
  border-color: colors.$color-angebot-dark;
}

.btn-outline-angebot:focus, .btn-outline-angebot.focus {
  box-shadow: 0 0 0 .2rem colors.$color-angebot-dark;
}

.btn-outline-angebot.disabled, .btn-outline-angebot:disabled {
  color: colors.$color-angebot-dark;
  background-color: transparent;
}

.btn-vertrag {
  border-color: rgba(0,0,0,0);
  background: colors.$color-vertrag-dark;
  color: #fff;
}

.btn-vertrag:hover {
  color: #fff;
}

.btn-outline-vertrag {
  color: colors.$color-vertrag-dark;
  border-color: colors.$color-vertrag-dark;
}

.btn-outline-vertrag:hover {
  color: #fff;
  background-color: colors.$color-vertrag-dark;
  border-color: colors.$color-vertrag-dark;
}

.btn-outline-vertrag:focus, .btn-outline-vertrag.focus {
  box-shadow: 0 0 0 0.2rem colors.$color-vertrag-dark;
}

.btn-outline-vertrag.disabled, .btn-outline-vertrag:disabled {
  color: colors.$color-vertrag-dark;
  background-color: transparent;
}

.btn-todo {
  border-color: colors.$color-medium-dark;
  background: colors.$color-todo-dark;
  color: colors.$color-medium-dark;
}

.btn-outline-todo {
  color: colors.$color-todo-dark;
  border-color: colors.$color-todo-dark;
}

.btn-outline-todo:hover {
  color: #fff;
  background-color: colors.$color-todo-dark;
  border-color: colors.$color-todo-dark;
}

.btn-outline-todo:focus, .btn-outline-todo.focus {
  box-shadow: 0 0 0 0.2rem colors.$color-todo-dark;
}

.btn-outline-todo.disabled, .btn-outline-todo:disabled {
  color: colors.$color-todo-dark;
  background-color: transparent;
}

.btn-blue {
  border-color: rgba(0,0,0,0);
  background: colors.$blue;
  color: #fff;
}

.btn-blue:hover {
  background-color: colors.$blue-light;
  color: #fff;
}

.btn-outline-blue {
  color: colors.$blue;
  border-color: colors.$blue;
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: colors.$blue;
  border-color: colors.$blue;
}

.btn-outline-blue:focus, .btn-outline-angebot.focus {
  box-shadow: 0 0 0 .2rem colors.$blue;
}

.btn-outline-blue.disabled, .btn-outline-angebot:disabled {
  color: colors.$blue;
  background-color: transparent;
}