@use '../variables' as variables;

/* Custom Text */
p {
  font-size: 0.875rem;
  color: variables.$dark;
  font-family: variables.$font-family-base;
  line-height: 21px;
  font-weight: 400;
}

/* Link */
a {
  transition: all .3s;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }
}

/* Text Decoration  */
.text-underline {
  text-decoration: underline!important;
  &:hover {
    text-decoration: underline!important;
  }
}

.text-hover-underline {
  text-decoration: none !important;
  &:hover {
    text-decoration: underline!important;
  }
}

/* selection Color */
::selection {
  color:  $white;
  background-color: variables.$primary;
}

/* Font Weight */
.font-weight-medium {
  font-weight: variables.$font-weight-medium !important;
}

.font-weight-semibold {
  font-weight: variables.$font-weight-semibold !important;
}

/* Font Size */
.font-size-base {
  font-size: variables.$font-size-base !important;
}

.font-size-13 {
  font-size: variables.$font-size-base * 0.96 !important;
}

.font-size-15 {
  font-size: variables.$font-size-base * 1.08 !important;
}

.font-size-22 {
  font-size: variables.$font-size-base * 1.57 !important;
}