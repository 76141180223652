@use 'variables';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

/** Form Control **/
.form-control {
  border-color: #e5e5e5;
  &::placeholder {
    color: variables.$gray-color;
  }
  &:focus {
    box-shadow: none;
    border-color:#e5e5e5;
    color: variables.$dark;
  }
  color: variables.$dark;
  font-weight: 500;
}
.form-control-plaintext {
  outline: 0;
}

.form-control-white {
  border-color:  $white;
  color:  $white !important;
  background-color: transparent;
  &::placeholder {
    color:  $white;
  }
  &:focus {
    background-color: transparent;
  }
}

.form-transparent {
  background-color: transparent !important;
  border: 1px solid #4a4e52 !important;
  border-radius: 5px;
}

.form-search {
  background-color: transparent !important;
  border-color: rgba(155,153,152,.25) !important;
}

.form-subscribe {
  .input-group-append {
    margin-left: -5px;
  }
}

.input-group-append {
  margin-left: 0;
}

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 3;
}

.form-group-icon-default,
.form-group-icon {
  position: relative;
  input.form-control {
    height: 45px;
  }
  i {
    top: 50%;
    right: 2px;
    font-size: .81rem;
    position: absolute;
    color: rgba( $white, .3);
    transform: translateY(-50%);
  }
}
.form-group-icon-default {
  i {
    right: 13px;
    color: variables.$gray-color;
  }
}

.form-group-icon-category-2,
.form-group-icon-white {
  i {
    color:  $white;
    right: 16px;
  }
}

.form-group-icon-category-2,
.form-group-icon-dark {
  i {
    color: #555;
  }
}

.form-check-label {
  cursor: pointer;
}

// custom-checkbox
.custom-control-label {
  outline: 0;
  span {
    color: variables.$gray-color;
    margin-left: 8px;
    font-size: 13px;
  }
}
.custom-checkbox .custom-control-label::after,
.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-control-label::before,
.custom-control-label::after {
  width: 19px;
  height: 19px;
  top: 0;
  border-color: #dedede;
  box-shadow: none !important;
  outline: 0 !important;
}

.settings-control-label::before,
.settings-control-label::after {
  width: 14px;
  height: 14px;
  top: 4px;
}

.radio-control-label::before,
.radio-control-label::after {
  width: 15px;
  height: 15px;
  top: 2px;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before,
.custom-control-input:not(:disabled):active ~ .custom-control-label::after {
  border-color: #dedede !important;
  background-color: transparent !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input:focus:not(:checked) ~ .custom-control-label::after {
  border-color: #dedede !important;
}

// count-input
.count-control-label {
  font-size: 13px;
  color: variables.$gray-color;
}

.count-input {
  position: relative;
  width: 140px;
  @include media-breakpoint-up(md) {
    margin: auto;
  }
  @include media-breakpoint-up(lg) {
    margin-left: 0;
    width: 120px;
  }
  @include media-breakpoint-up(xl) {
    width: 140px;
  }
  .incr-btn {
    color: #666;
    display: block;
    font-size: 30px;
    font-weight: 300;
    height: 48px;
    line-height: 45px;
    position: absolute;
    right: 0;
    text-align: center;
    text-decoration: none;
    top: 0;
    width: 48px;

    @include media-breakpoint-up(lg) {
      width: 40px;
    }
    @include media-breakpoint-up(xl) {
      width: 48px;
    }
    &:first-child {
      left: 0;
      right: auto;
      border-right: 1px solid #eaeaea;
    }
    &:last-child {
      border-left: 1px solid #eaeaea;
    }
  }
  input {
    -moz-appearance: none;
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #eaeaea;
    border-radius: variables.$border-radius;
    height: 48px;
    font-weight: 700;
    color: #666;
    text-align: center;
    width: 100%;
    &:focus {
      outline: 0;
    }
  }
}

input[type=number]::-webkit-inner-spin-button {
  display: none;
}