/* Custom Letter spacing */
.letter-spacing-2 {
  letter-spacing: 2px;
}
.letter-spacing-3 {
  letter-spacing: 3px;
}
.letter-spacing-4 {
  letter-spacing: 4px;
}
.letter-spacing-5 {
  letter-spacing: 5px;
}
.letter-spacing-10 {
  letter-spacing: 10px;
}
.letter-spacing-15 {
  letter-spacing: 15px;
}

/* Custom Line Height */
.line-hight-16 {
  line-height: 1rem;
}
.line-hight-20 {
  line-height: 1.25rem;
}
.line-hight-21 {
  line-height: 1.3125rem;
}
.line-height-24 {
  line-height: 1.5rem !important;
}
.line-height-26 {
  line-height: 1.625rem !important;
}
.line-height-28 {
  line-height: 1.75rem !important;
}