@use 'variables';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";


/* Revolution Slider */
#rev_slider_12_1 .metis.tparrows {
	background: rgba(255, 255, 255, 0.15);
	padding: 0px;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
	width: 40px;
	height: 40px;
	box-sizing: border-box;
	@include media-breakpoint-up(md) {
		padding: 10px;
		width: 60px;
		height: 60px;
	}
	@include media-breakpoint-up(lg) {
		padding: 27px;
		width: 85px;
		height: 85px;
	}
	@include media-breakpoint-up(xl) {
		padding: 25px;
	}
}
#rev_slider_12_1 {
	.rev-btn {
		background-color: variables.$primary !important;
	}
}

#rev_slider_12_1 .metis.tparrows:hover {
  background: rgba(255, 255, 255, 0.2)
}

#rev_slider_12_1 .metis.tparrows:before {
	color: rgb(255, 255, 255);
	transition: all 0.3s;
	-webkit-transition: all 0.3s
}

#rev_slider_12_1 .metis.tparrows:hover:before {
  transform: scale(1.5)
}

.hesperiden.tp-bullets:before {
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	background: transparent;
	padding: 10px;
	margin-left: -10px;
	margin-top: -10px;
	box-sizing: content-box;
	border-radius: 8px
}

.hesperiden .tp-bullet {
	width: 12px;
	height: 12px;
	position: absolute;
	background: rgba(255, 255, 255, 0.01);
	background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.01) 100%);
	background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.01) 100%);
	background: -o-linear-gradient(top, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.01) 100%);
	background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.01) 100%);
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.01) 100%);
	border: 2px solid rgb(255, 255, 255);
	border-radius: 50%;
	cursor: pointer;
	box-sizing: content-box
}

.hesperiden .tp-bullet:hover,
.hesperiden .tp-bullet.selected {
  background: rgba(225, 225, 225, .7);
}

/* Home City */
#rev_slider_18_1 .metis.tparrows {
	background: rgba(255, 255, 255, 0.15);
	padding: 0px;
	transition: all 0.3s;
	-webkit-transition: all 0.3s;
	width: 40px;
	height: 40px;
	box-sizing: border-box;
	@include media-breakpoint-up(md) {
		padding: 10px;
		width: 60px;
		height: 60px;
	}
	@include media-breakpoint-up(lg) {
		padding: 27px;
		width: 85px;
		height: 85px;
	}
	@include media-breakpoint-up(xl) {
		padding: 25px;
	}
}
#rev_slider_18_1 {
	.rev-btn {
		&:hover {
			background-color: variables.$primary !important;
			border-color: variables.$primary !important;
		}
	}
}

#rev_slider_18_1 .metis.tparrows:hover {
  background: rgba(255, 255, 255, 0.2)
}

#rev_slider_18_1 .metis.tparrows:before {
	color: rgb(255, 255, 255);
	transition: all 0.3s;
	-webkit-transition: all 0.3s
}

#rev_slider_18_1 .metis.tparrows:hover:before {
  transform: scale(1.5)
}

/* Revolution Slider Navigation */
#rev_slider_wrapper1 {
	#rev_slider_container {
		.slide-layer-4 {
			a {
				color:  $white;
			}
			text-transform: uppercase;
			background-color: variables.$primary;
			border: 0;
			&:hover {
				background: variables.$primary;
				cursor: pointer;
			}
		}
	}
}

#rev_slider_wrapper2{
	#rev_slider_container{
		.slide-layer-1{
			font-weight: 700;
			line-height: 55px;
			color: #fff;
			text-transform: uppercase;
			z-index: 10;

		}
		.slide-layer-2{
			font-size: 20px;
			font-weight: 400;
			line-height: 21px;
			color: #fff;
			z-index: 10;
		}

		.slide-layer-3{
			color: #fff;
			text-transform: uppercase;
			border: 2px solid #fff;
			&:hover{
				background: variables.$primary;
				border: 2px solid variables.$primary;
				cursor: pointer;
			}
			&:focus{
				text-decoration: none;
			}
		}
		.slide-layer-4{
			color: #fff;
			text-transform: uppercase;
			border: 2px solid #fff;

			&:hover{
				background: variables.$primary;
				border: 2px solid variables.$primary;
				cursor: pointer;
			}
			&:focus{
				text-decoration: none;
			}
		}
	}
}

#rev_slider_wrapper3 {
	#rev_slider_container {
		.slide-layer-2 {
			text-transform: uppercase;
		}
		.slide-layer-3 {
			.container-container-custom {
				width: 530px !important;
				margin-left: -15px;
				margin-right: -15px;
			}
		}
		.tp-rightarrow {
			display: none;
		}
		.tp-leftarrow {
			display: none;
		}
		.tp-bullet {
			display: none;
		}
	}
}

/* Banner Container */
.bannercontainer {
	.rev_slider_wrapper {
		.fullscreenbanner {
			position: relative;
			.tp-dottedoverlay.twoxtwo {
				background: rgba(0, 0, 0, 0.15);
				background-image: none!important;
				z-index: 1;
			}
			font-family: variables.$font-family-base;
			&:hover {
				.tp-rightarrow.tparrows,
				.tp-leftarrow.tparrows {
					display: block;
				}
      }
      
      /*Revolution Slider  Arrows */
			.tp-rightarrow {
				&.tparrows {
					left: 101.5% !important;
					display: none;
					border-radius: 0;
					@include media-breakpoint-up(md) {
						width: 60px;
						height: 60px;
					}
					@include media-breakpoint-up(lg) {
						width: 85px;
						height: 85px;
					}
					background-color: rgba(255, 255, 255, 0.1);
					&:hover {
						background-color: rgba(255, 255, 255, 0.1);
					}
					&:before {
						content: '\e825';
						@include media-breakpoint-up(md) {
							line-height: 60px;
						}
						@include media-breakpoint-up(lg) {
							line-height: 85px;
						}
					}
				}
			}
			.tp-leftarrow {
				&.tparrows {
					display: none;
					left: -20px !important;
					border-radius: 0;
					@include media-breakpoint-up(md) {
						width: 60px;
						height: 60px;
					}
					@include media-breakpoint-up(lg) {
						width: 85px;
						height: 85px;
					}
					background-color: rgba(255, 255, 255, 0.1);
					&:hover {
						background-color: rgba(255, 255, 255, 0.1);
					}
					&:before {
						content: '\e824';
						@include media-breakpoint-up(md) {
							line-height: 60px;
						}
						@include media-breakpoint-up(lg) {
							line-height: 85px;
						}
					}
				}
      }
      
      /*Revolution Slider  Bullets */
			.tp-bullets {
				top: 97% !important;
				opacity: 1 !important;
				height: auto !important;
				@include media-breakpoint-up(lg) {
					bottom: 96px !important;
				}
				.tp-bullet {
					background: rgba(255, 255, 255, 0.5) !important;
					@include border-radius (50%);
					box-shadow: none!important;
					width: 6px !important;
					height: 6px !important;
					border: 2px solid rgba(255, 255, 255, 0.0) !important;
					display: inline-block;
					margin-bottom: 0px !important;
					@include transition (background-color 0.2s, border-color 0.2s);
					// float: none !important;
					&.selected {
						box-shadow: none!important;
						background: rgba(255,255,255,0)!important;
						width: 14px!important;
						height: 14px!important;
						border: 2px solid white!important;
						margin-right: 0px!important;
						margin-left: -4px!important;
						margin-bottom: -3px!important;
						margin-top: -3px;
					}
					&.hover {
						box-shadow: none!important;
						background: rgba(255, 255, 255, 0.0) !important;
						width: 14px !important;
						height: 14px !important;
						border: 2px solid rgba(255, 255, 255, 1) !important;
						margin-bottom: -3px!important;
					}
				}
			}
		}
	}
}

/* Revolution Slider  boxed version */
.boxed {
	.forcefullwidth_wrapper_tp_banner {
		overflow: hidden !important;
	}
}