@import '../../website_theme/scss/variables';

#toast-container.toast-bottom-full-width>div, #toast-container.toast-top-full-width>div {
  margin-top: 50px !important;
}

.toast-success {
  background-color: $info !important;
}

.toast-warning {
  background-color: $warning !important;
  color: $stone !important;
}

.toast-error {
  background-color: $danger !important;
}

#toast-container>div {
  opacity: 1 !important;
}