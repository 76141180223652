.rotate-45 {
    transform: rotate(45deg)!important
}

.rotate-90 {
    transform: rotate(90deg)!important
}

.rotate-135 {
    transform: rotate(135deg)!important
}

.rotate-180 {
    transform: rotate(180deg)!important
}
