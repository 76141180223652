@use 'variables';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

/* Custom Counter */
.counter-up {
  .icon {
    &.bg-primary {
      background-color: rgba(variables.$primary, .8) !important;
    }
    &.bg-secondary {
      background-color: rgba($secondary, .8) !important;
    }
    &.bg-success {
      background-color: rgba(variables.$success, .8) !important;
    }
    &.bg-danger {
      background-color: rgba(variables.$danger, .8) !important;
    }
    &.bg-warning {
      background-color: rgba(variables.$warning, .8) !important;
    }
    &.bg-info {
      background-color: rgba(variables.$info, .8) !important;
    }
  }
  h1 {
    font-size: 32px;
    color: $white;
    @include media-breakpoint-up(lg) {
      font-size: 52px;
    }
  }
  h6 {
    color: $white;
    font-size: 13px;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
      font-size: 15px;
    }
  }
}