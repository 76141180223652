
[class^="weather-"] {
    height: 1.4em;
    width: 1.4em;
    background-color: #0b76b8;
    border-radius: 5px;
    margin-bottom: -0.3em;
}

.weather-clear-day {
    content: url("svg/clear-day.svg");
}

.weather-clear-night {
    content: url("svg/clear-night.svg");
}

.weather-partly-cloudy-day
{
    content: url("svg/partly-cloudy-day.svg");
}

.weather-partly-cloudy-night
{
    content: url("svg/partly-cloudy-night.svg");
}

.weather-cloudy
{
    content: url("svg/cloudy.svg");
}

.weather-overcast
{
    content: url("svg/overcast.svg");
}

.weather-rain
{
    content: url("svg/rain.svg");
}

.weather-partly-cloudy-day-rain
{
    content: url("svg/partly-cloudy-day-rain.svg");
}

.weather-partly-cloudy-night-rain
{
    content: url("svg/partly-cloudy-night-rain.svg");
}

.weather-thunderstorm
{
    content: url("svg/thunderstorms.svg");
}

.weather-snow
{
    content: url("svg/snow.svg");
}

.weather-mist
{
    content: url("svg/mist.svg");
}
