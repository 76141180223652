@use 'variables';

/* Accordion */
.accordion {
  .card:not(:first-of-type):not(:last-of-type) {
    border: 0;
  }
  .card:first-of-type {
    border: 0;
  }
  .card:last-of-type {
    border: 0;
  }
  .card {
    border-radius: 0;
    margin-bottom: 0.9375rem;
    background-color: transparent;
    .card-header {
      padding: 0;
      /*border-bottom: 1px solid transparent;*/
      background-color: transparent;
      h5,div {
        cursor: pointer;
        margin-bottom: 0;
        font-family: variables.$font-family-base;
        font-weight: 700;
        color: variables.$gray-color;
        font-size: 13px;
        padding: 0.75rem 2.1875rem 0.75rem 0;
        position: relative;
        span {
          display: block;
        }
        &.icon-bg {
          position: relative;
          /*border-bottom: 1px solid #e5e5e5;*/
          background-color: transparent;
          transition: all .3s;
          &:before {
            position: absolute;
            top: 50%;
            font-style: normal;
            font-size: 20px;
            font-weight: bold;
            /*
            padding: .125rem 0.187rem;
            */
            transform: translateY(-50%) rotate(0deg);
            content: '-';
            padding-bottom: 5px;
            padding-right: 3px;
            right: 0;
            color: variables.$gray-color;
          }
          &.collapsed {
            background-color: transparent;
            color: variables.$gray-color;
            /*border-bottom: 1px solid transparent;*/
            transition: all .3s;
            &:before {
              transform: translateY(-50%) rotate(0deg);
              color: variables.$gray-color;
              content: '+';
              padding-bottom: 0;
              padding-right: 0;
            }
          }
        }
      }
    }
    .card-body {
      padding: 1.875rem 0 0;
    }
  }
}