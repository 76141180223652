@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

/* Section Titile */
.section-title, .section-title2 {
  position: relative;
  margin-bottom: 30px;
  @include media-breakpoint-up(md) {
    margin-bottom: 48px;
  }

  &:before {
    content: "";
    top: 11px;
    width: 100%;
    height: 1px;
    display: block;
    position: absolute;
    background-color: #e5e5e5;
    @include media-breakpoint-up(md) {
      top: 14px;
    }
  }

  span {
    padding-left: 38px;
    padding-right: 38px;
  }
  
	h2 {
    font-size: 20px;
    margin-bottom: 16px;
		@include media-breakpoint-up(md) {
			font-size: 26px;
		}
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
			font-size: 18px;
		}
  }
}

.section-title2 {
  span {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  &:before {
    display: none;
  }
}

.section-title-bg {
  @include media-breakpoint-up(md) {
    background-image: url(../img/home/devider.png);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 8px 45px;
  }
  h1 {
    font-size: 14px;
    @include media-breakpoint-up(lg) {
			font-size: 18px;
    }
    @include media-breakpoint-up(xl) {
			font-size: 20px;
		}
  }
  h2 {
    font-size: 30px;
    @include media-breakpoint-up(lg) {
			font-size: 22px;
    }
    @include media-breakpoint-up(xl) {
			font-size: 26px;
		}
  }
}

.section-top {
  padding-top: 94px;
  @include media-breakpoint-up(lg) {
    padding-top: 0;
  }
}

.element-title {
	margin-bottom: 1rem;
}