@font-face {
  font-family: 'barbericon';
  src:  url('fonts/barbericon.eot?2674fp');
  src:  url('fonts/barbericon.eot?2674fp#iefix') format('embedded-opentype'),
    url('fonts/barbericon.ttf?2674fp') format('truetype'),
    url('fonts/barbericon.woff?2674fp') format('woff'),
    url('fonts/barbericon.svg?2674fp#barbericon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="by-"], [class*=" by-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'barbericon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.by-cabins:before {
  content: "\e900";
}
.by-ship:before {
  content: "\e901";
}
.by-ship-type:before {
  content: "\e902";
}
.by-airplane:before {
  content: "\e90c";
}
.by-calender:before {
  content: "\e90d";
}
.by-chair:before {
  content: "\e90e";
}
.by-closed:before {
  content: "\e90f";
}
.by-electric:before {
  content: "\e910";
}
.by-gps:before {
  content: "\e911";
}
.by-solar:before {
  content: "\e912";
}
.by-steering-wheel:before {
  content: "\e913";
}
.by-thermometer:before {
  content: "\e914";
}
.by-ventilation:before {
  content: "\e915";
}
.by-wind:before {
  content: "\e916";
}
