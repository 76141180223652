@use '../variables' as variables;

/* Background Parallax */
.bg-parallax {
  background-attachment: fixed;
}

/* Background Repeat */
.bg-repeat {
  background-repeat: repeat;
}

.bg-img {
  background-size: cover !important;
  background-position: center !important;
  transition: all 0.3s linear;
  position: relative;
}

.bg-overlay {
	&:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	}
}

.bg-overlay-primary {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(variables.$primary, .3);
  }
}

.bg-overlay-light {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(variables.$black, .15);
  }
}

.bg-overlay-dark {
	&:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(variables.$black, .6);
	}
}

.bg-overlay-darken {
	&:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(variables.$black, .5);
	}
}

.bg-overlay-warning {
	&:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(variables.$primary, .7);
	}
}

.img-overlay {
  position: relative;
  overflow: hidden;
}

.img-overlay-dark {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(variables.$black, .3);
}