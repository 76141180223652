.shariff-button {
  margin-right: 0.2rem !important;
  margin-bottom: 0 !important;
  border-radius: 20%;
}

.shariff .theme-grey .shariff-button a:not(:hover) {
  background-color: #a4bf81;
}

.shariff li .fab, .shariff li .far, .shariff li .fas {
  width: 100%;
}
