@use 'variables';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

/* Selectric Default */
.select-default {
  .selectric {
    background-color: transparent;
    border: 1px solid  $border-color;
    border-radius: variables.$border-radius;
    .label {
      padding: .8rem 1.5rem;
      font-size: .875rem;
      color: variables.$gray-color;
      font-family: variables.$font-family-base;
    }
    .button {
      height: 42px;
      width: 46px;
      background: transparent;
      top: 50%;
      transform: translateY(-50%);;
      right: 2px;
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: fontawesome;
        content: '\f107';
        font-size: 1rem;
        color: variables.$gray-color;
      }
    }
  }
  .selectric-open .selectric {
    margin-top: 0;
  }
  .selectric-items {
    &::before,
    &::after {
      display: none;
    }
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-color:  $border-color;
    .selectric-scroll {
      li {
        border-bottom: 1px solid  $border-color;
        padding: 8px 30px 8px 15px;
        font-size: 12px;
        color: variables.$dark;
        font-weight: 500;
        &:hover {
          background-color: lighten($gray-300, 5%);
          color: variables.$primary;
        }
        &.highlighted {
          background-color: lighten($gray-300, 5%);
          color: variables.$primary;
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

/* Selectric Confirm */
.select-confirm {
  .selectric {
    background-color: variables.$smoke;
    border: 0;
  }
}

/* Selectric White */
.select-white {
  .selectric {
    background-color: transparent;
    border-radius: 5px;
    .label {
      color:  $white;
      text-transform: uppercase;
      font-family: sans-serif;
      font-size: 14px;
    }
    .button {
      background-color: transparent;
      &::before {
        color:  $white;
      }
    }
  }
  .selectric-items {
    border-radius: 5px;
    .selectric-scroll {
      li {
        font-size: 14px;
      }
    }
  }
}

/* Selectric Category-1 */
.select-category-1 {
  .selectric {
    background-color: transparent;
    border-color: transparent;
    border-bottom-color: #dedede;
    border-radius: 0;
    .label {
      font-size: 12px;
      padding-left: 0;
      padding-right: 0;
      margin: 0;
    }
    .button {
      width: auto;
      right: 5px;
    }
  }
  .selectric-items {
    border-radius: 5px;
    .selectric-scroll {
      li {
        font-size: 14px;
      }
    }
  }
}

/* Selectric Category-2 */
.select-category-2 {
  .selectric {
    background-color: transparent;
    border-color: #dedede;
    border-radius: 3px;
    .label {
      color: #666;
      font-size: 14px;
    }
    .button {
      background-color: transparent;
      &::before {
        color: #666;
      }
    }
  }
  .selectric-items {
    border-radius: 5px;
    .selectric-scroll {
      li {
        font-size: 14px;
      }
    }
  }
}

.timer {
  .selectric {
    .button {
      &::before {
        content: '\f017';
        font-size: 13px;
      }
    }
  }
}

/* Selectric Search Box */
.select-search-box {
  .selectric {
    background-color: transparent;
    border-color: transparent;
    border-bottom-color: variables.$primary;
    border-radius: 0;
    .label {
      font-size: 14px;
      padding-left: 0;
      padding-right: 0;
      margin: 0;
      color: rgba( $white, .3);
    }
    .button {
      width: auto;
      right: 5px;
      &::before {
        color: rgba( $white, .3);
      }
    }
  }
  .selectric-items {
    border-radius: 5px;
    .selectric-scroll {
      li {
        font-size: 14px;
      }
    }
  }
}

.select-text-only {
  .selectric {
    background-color: transparent;
    border: none;
    padding-right: 1.25rem !important;
    .label {
      font-size: 14px;
      padding-left: 0;
      padding-right: 0;
      margin: 0;
      color: rgba( $white, .3);
    }
    .button {
      width: auto;
      right: 20px;
      &::before {
        color: rgba( $white, .3);
      }
    }
  }
}
.text-only-icon {
  top: 50%;
  right: 20px !important;
  font-size: .81rem;
  position: absolute;
  color: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%);
}

/* Selectric Option */
.select-features {
  .selectric {
    .label {
      text-transform: capitalize;
    }
  }
}

.select-search-box .selectric .label, .form-group-icon-default i, .form-group-icon i {
  color: variables.$dark;
}

.select-search-box .selectric .label {
  font-weight: 500;
}

.select-default .selectric .button::before {
  content: none;
}
.selectric-items {
  white-space: nowrap;
  width: auto !important;
}

.selectric-items ul, .selectric-items li {
  max-height: 310px;
}

.select-default .selectric {
  border-left: 0;
  border-right: 0;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: 700 !important;
}

.selectric-group-label:hover {
  background: white !important;
  color: variables.$dark !important;
}