@use 'variables';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

/* Secelete2 */
.select2-select {
  width: 100% !important;
}

.select2 {
  width: 100% !important;
}

.select2-container--default .select2-selection--single {
  &:focus {
    outline: 0;
  }
}

.select-dropdown-top {
  margin-top: 0;
  .select2-container--default .select2-selection--single {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    height: 30px;
    line-height: 30px;
    position: relative;
    &:before {
      position: absolute;
      content: '\f107';
      font-family: 'FontAwesome';
      font-style: normal;
      right: 0;
      color:  $white;
    }
    .select2-selection__arrow b {
      top: 100%;
      left: 0;
      display: none;
    }
    .select2-selection__rendered {
      color:  $white;
      font-weight: 400;
      line-height: 30px;
      padding-left: 20px;
    }
  }

  .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.select-dropdown {
  transition: all .9s;
  .select2-container--default .select2-selection--single {
    transition: all .9s;
    background-color: transparent;
    border: 1px solid transparent;
    border-bottom: 1px solid rgba( $white, .3);
    border-radius: 0;
    height: 45px;
    line-height: 45px;
    position: relative;
    &:before {
      position: absolute;
      content: '\f107';
      font-family: 'FontAwesome';
      font-style: normal;
      right: 0;
      color: rgba( $white, .3);
    }
    .select2-selection__arrow b {
      top: 100%;
      left: 0;
      display: none;
    }
    .select2-selection__rendered {
      color: rgba( $white, .3);
      font-size: 13px;
      font-weight: 400;
      line-height: 45px;
      padding-left: 0;
    }
  }

  .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &.text-color {
    .select2-container--default .select2-selection--single {
      border-bottom: 1px solid #dedede !important;
      .select2-selection__rendered, &:before {
        color: variables.$gray-color !important;
      }
    }
  }
}

.select-dropdown-option {
  .select2-container--default .select2-selection--single {
    background-color:  $white;
    border: 1px solid  $border-color;
    border-radius: 0;
    height: 50px;
    line-height: 50px;
    position: relative;
    &:before {
      position: absolute;
      content: '\f017';
      font-family: 'FontAwesome';
      font-style: normal;
      right: 20px;
      color: variables.$gray-color;
    }
    .select2-selection__arrow b {
      top: 100%;
      left: 0;
      display: none;
    }
    .select2-selection__rendered {
      color: variables.$gray-color;
      font-weight: 400;
      line-height: 50px;
      padding-left: 20px;
    }
  }

  .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

// dropdown
.select2-search--dropdown {
  //display: none;
}

.select2-container--open .select2-dropdown--below {
  border: 1px solid #ddd;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.select2-results__option[aria-selected] {
  border-bottom: 1px solid #ddd;
  font-family: open sans,sans-serif;
  font-size: 14px;
  padding: 8px 17px;
  text-transform: capitalize;
  transition: all .4s;
  color: variables.$dark;
  font-weight: 500;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color:  $white;
  color: variables.$gray-color;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color:  $white;
  color: variables.$dark;
  font-weight: 500;
  &:hover {
    color: variables.$primary;
    background-color: $gray-100;
  }
}

.select2-container--default .select2-results>.select2-results__options {
  color: variables.$dark;
  font-weight: 500;
}

/* z.B Yacht-Suche */
.custom-select2 .select2-container .select2-selection {
  color: black;
  background-color: transparent;
  border-color: transparent transparent variables.$primary;
  border-radius: 0;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #edeff1;
  color: variables.$primary;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: black;
  border: 0;
  background-color: transparent;
  font-size: 14px;
  padding-left: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field {
  padding-bottom: 5px;
  font-size: 14px;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-input-placeholder {
  color: #575757;
  font-weight: 500;
}

/* z.B Freie Anfrage */
.custom-select2-default .select2-container .select2-selection {
  font-size: 16px;
  border-color: #e5e5e5;
  border-radius: 0.2rem;
}

.custom-select2-default .select2-container .select2-search--inline .select2-search__field {
  margin-top: 0;
  font-size: 0.875rem;
  padding-top: 0.3rem;
  padding-bottom: 0;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}

.custom-select2-default .select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding: 0.4rem;
  margin-left: 5px;
  margin-right: 0;
  background-color: variables.$primary-light;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  border-color: #e5e5e5;
  cursor: pointer;
  display: block;
  min-height: calc(1.5em + 0.8rem + 2px);
  user-select: none;
  font-weight: 500;
  -webkit-user-select: none;
}

.select2-selection .select2-selection--single {
  margin-top: 0.63rem !important;

}

.custom-region-select2 > .select2-container > .selection > .select2-selection--single {
  margin-top: 5px !important;
}

.custom-region-select2 > .select2-container > .selection > .select2-selection--single > .select2-selection__rendered {
  margin-top: -5px;
  font-weight: 500 !important;
  color: #222 !important;
  padding-left: 0 !important;
}

.custom-airport-select2 > .select2-container > .selection > .select2-selection--single > .select2-selection__rendered {
  font-weight: 500 !important;
  color: #222 !important;
  padding-left: 0 !important;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #222 !important;
  padding-left: 5px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-top: 3px;
}

.select2-search__field {
  width: 100% !important;
}

.select2-dropdown {
  min-width: 200px !important;
}
.select2-results__group {
  padding-left: 1em !important;
  background-color: #f5f5f5;
  color: #747474;
}
