@use 'variables';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

/* Navbar Custom */
.navbar {
  padding: 11px 0;
  z-index: 100;
  transition: .4s ease-in-out;
  position: relative;
  @include media-breakpoint-up(md) {
    padding: 0;
  }
  > .container {
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }
  .navbar-brand {
    margin-left: 15px;
    margin-right: 0;
    padding: 16px;
    @include media-breakpoint-up(md) {
      padding: 0;
    }
    img {
      width: 75%;
      @include media-breakpoint-up(md) {
        width: 80%;
      }
      @include media-breakpoint-up(lg) {
        width: 100%;
      }
    }
  }

  .navbar-toggler {
    margin-right: 20px;
    background-color:  $white;
    border-color: #ddd;
    padding: 0.4375rem .75rem;
    color:  $white;
    outline: 0;
    .icon-bar {
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
      background-color: #888;
      margin: 4px 0;
    }
  }

  .navbar-collapse {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 10;
    background-color:  $white;
    max-height: 400px;
    margin-top: 5px;
    box-shadow: 0 4px 6px rgba(0,0,0, .175);
    @include media-breakpoint-up(md) {
      position: static;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      box-shadow: none;
    }
    &.show {
      overflow-y: auto;
      @include media-breakpoint-up(md) {
        overflow-y: hidden;
      }
    }
  }
}

.dropdown-menu {
  border-radius: 0;
}

.navbar-nav {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-up(md) {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
  .dropdown {
    > a {
      font-size: .875rem;
    }
    .nav-link {
      line-height: normal;
      font-size: 13px;
      color: variables.$black;
      font-weight: normal;
      text-transform: capitalize;
      margin-left: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
      i {
        margin-right: 7px;
      }
      @include media-breakpoint-up(md) {
        display: block;
        width: auto;
        margin-left: 0;
        padding: 28px 7px 10px;
        font-size: 13px;
      }
      @include media-breakpoint-up(lg) {
        padding: 30px 15px 17px;
        font-size: 14px;
      }
      @include media-breakpoint-up(xl) {
        padding: 30px 15px 17px;
      }
    }
    .dropdown-menu {
      border: 0;
      padding-top: 0;
      padding-bottom: 0;
      z-index: 200;
      @include media-breakpoint-up(md) {
        border: 1px solid rgba(0,0,0,.15);
        margin-top: 0;
        width: 210px;
        padding: 5px;
        padding-top: 15px;
        padding-bottom: 21px;
        padding-left: 15px;
        margin-top: -1px;
        box-shadow: 0 4px 6px rgba(0,0,0, .175);
      }
      @include media-breakpoint-up(lg) {
        width: 230px;
      }
      li {
        position: relative;
      }
      ul li a {
        display: inline-block;
        color: variables.$gray-color;
        padding-top: 6px;
        padding-bottom: 6px;
        font-size: .8125rem;
        text-transform: uppercase;
        font-weight: 400;
        transition: all, .3s ease-in-out;
        @include media-breakpoint-up(md) {
          padding: 10px 12px;
          display: inline-block;
          text-transform: capitalize;
          font-size: .75rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: .875rem;
        }
      }
      .dropdown-item {
        color: variables.$gray-color;
        padding-top: 6px;
        padding-bottom: 6px;
        font-size: .8125rem;
        text-transform: uppercase;
        font-weight: 400;
        padding-left: 50px;
        transition: all, .3s ease-in-out;
        @include media-breakpoint-up(md) {
          padding: 10px 12px;
          display: inline-block;
          text-transform: capitalize;
          font-size: .75rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: .875rem;
        }
      }
      .sub-menu {
        list-style: none;
        padding-left: 20px;
        margin-left: 50px;
        position: relative;
        @include media-breakpoint-up(md) {
          position: absolute;
          left: 100%;
          top: 0;
          background-color:  $white;
          width: 185px;
          visibility: hidden;
          opacity: 0;
          margin-left: 3px;
          padding: 5px;
          border-radius: 4px;
          border-left: 4px solid;
          box-shadow: 0 4px 6px rgba(0,0,0,.175);
        }
        @include media-breakpoint-up(lg) {
          width: 190px;
        }
        @include media-breakpoint-up(xl) {
          width: 210px;
        }
        &:before {
          position: absolute;
          left: 0;
          height: 90%;
          top: 50%;
          transform: translateY(-50%);
          width: 3px;
          background-color:  $border-color;
          content: '';
          @include media-breakpoint-up(md) {
            display: none
          }
        }
        &:after {
          @include media-breakpoint-up(md) {
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-right: 7px solid variables.$black;
            top: 20px;
            margin-top: -7px;
            content: "";
            display: inline-block;
            left: -10px;
            position: absolute;
          }
        }
      }
      li:hover {
        .sub-menu {
          visibility: visible;
          opacity: 1;
        }
      }
      i {
        display: none;
        @include media-breakpoint-up(md) {
          display: inline-block;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &.show {
        .nav-link.dropdown-toggle:after {
          content: "\f106";
          @include media-breakpoint-up(md) {
            content: '';
          }
        }
      }
    }
    .dropdown-toggle {
      &:after {
        display: inline-block;
        width: auto;
        height: auto;
        font-family: fontawesome;
        margin-left: 5px;
        vertical-align: middle;
        content: "\f107";
        border: 0;
        color: inherit;
        position: absolute;
        top: 20px;
        right: 15px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 1.1rem;
        @include media-breakpoint-up(md) {
          content: '';
        }
      }
    }
    &.show {
      .dropdown-toggle {
        &:after {
          content: "\f106";
          @include media-breakpoint-up(md) {
            content: '';
          }
        }
      }
    }
  }
}

.navbar-expand-md .navbar-nav .nav-link {
  @include media-breakpoint-up(md){
    padding-top: 14px;
    padding-bottom: 14px;
  }
  @include media-breakpoint-up(md){
    padding-top: 23px;
    padding-bottom: 23px;
  }
}

.navbar-expand-md {
  .mega-dropdown {
    @include media-breakpoint-up(md) {
      position: static;
    }
  }
}

.nav-icon {
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 100%;
  display: inline-block;
  color:  $white;
  font-size: 1rem;
  margin-right: 10px;
  @include media-breakpoint-up(md) {
    margin: 0 auto 10px;
    margin-bottom: 10px;
    display: block;
  }
}

.dropdown-menu.row.show {
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-up(md) {
    width: 100%;
    padding-bottom: 25px;
    padding-left: 0;
    padding-right: 0;
  }
  .list-unstyled {
    position: relative;
    li {
      padding-left: 35px;
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: .8125rem;
      @include media-breakpoint-up(md) {
        padding-top: 0;
        padding-bottom: 0;
        font-size: .94rem;
        padding-left: 0;
      }
      a {
        color: variables.$gray-color;
      }
      &:first-child {
        font-weight: 400;
        padding-left: 25px;
        font-size: .875rem;
        text-transform: uppercase;
        @include media-breakpoint-up(md) {
          margin-top: 13px;
          margin-bottom: 13px;
          padding-bottom: 12px;
          padding-left: 0;
          margin-left: 13px;
          font-size: .8125rem;
        }
        @include media-breakpoint-up(lg) {
          font-size: .875rem;
        }
      }
    }
    &:before {
      position: absolute;
      left: 0;
      height: 60%;
      top: 50%;
      transform: translateY(-28%);
      width: 3px;
      background-color: transparent;
      content: '';
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}

.nav-item.dropdown.active {
  .nav-link {
    font-weight: 500 !important;
    color: variables.$primary-dark !important;
    @include media-breakpoint-up(md) {
      color:  $white !important;
    }
  }
}

.nav-item.dropdown {
  background-color: transparent !important;
  .nav-link {
    color: variables.$dark;
    font-weight: 400;
    /*
    @include media-breakpoint-up(md) {
      color:  $white;
    }
    */
    &.active {
      color: variables.$primary !important;
      @include media-breakpoint-up(md) {
        color: variables.$dark !important;
      }
    }
    &:hover {
      color: variables.$primary !important;
      @include media-breakpoint-up(md) {
        color:  $white !important;
      }
    }
  }
  .dropdown-menu {
    // border-color:  $white;
    border-radius: 5px;
    // border: 1px solid rgba(0,0,0,.15);
    li {
      a {
        &:hover {
          background-color: transparent;
          color: variables.$primary;
          border-radius: 10px;
        }
        &.active {
          background-color: transparent;
          color: variables.$dark !important;
          border-radius: 10px;
          @include media-breakpoint-up(md) {
            color: variables.$primary !important;
          }
        }
      }
      .dropdown-item.active {
        background-color: transparent;
        color: variables.$primary;
        &:hover {
          background-color: transparent;
          color: $white;
        }
      }
      .sub-menu {
        border-left-color: variables.$primary;
        &:after {
          border-right-color: variables.$primary;
        }
      }
      &:first-child {
        color: variables.$dark;
      }
    }
    @include media-breakpoint-up(md) {
      li {
        a {
          &:hover {
            background-color: transparent;
            color: variables.$primary !important;
          }
           &.active {
            color: variables.$primary;
          }
        }
      }
    }
  }
  .dropdown-toggle {
    &.active {
      &:after {
        color: variables.$primary;
      }
    }
  }
}

/* Navbar Nav Right*/
.navbar-nav-right {
  @include media-breakpoint-up(md) {
    padding-right: 30px;
  }
  .nav-item-left.dropdown {
    margin-right: 10px;
    .nav-link {
      .icon {
        color: rgba($white, .4);
      }
      .badge-pill {
        position: absolute;
        top: -6px;
        right: 3px;
        border-radius: 3px;
        font-size: 11px;
        padding: 0 5px;
        @include media-breakpoint-up(lg) {
          
        }
      }
    }
    .dropdown-left {
      top: 100%;
      min-width: 228px;
      margin: 0;
      margin-top: 12px;
      padding: 0;
      @include media-breakpoint-up(md) {
        min-width: 320px;
      }
      &::before {
        position: absolute;
        content: "";
        background: $white;
        width: 12px;
        height: 12px;
        border-radius: 3px 0 0 0;
        transform: rotate(45deg);
        top: -6px;
        right: 16px;
      }
      .list-group {
        .list-group-item {
          padding: 0.92rem 1.25rem;
          @include media-breakpoint-up(lg) {
            padding: 0.78rem 1.25rem;
          }
          &:hover {
            background-color: #fafafa;
          }
          &.first-child {
            border-radius: variables.$border-radius variables.$border-radius 0 0 !important;
          }
          &.last-child {
            border-radius: 0 0 variables.$border-radius variables.$border-radius !important;
          }
        }
      }
      
    }
  }
  .nav-item-left {
    position: relative;
    right: 0;
    .dropdown-toggle {
      position: relative;
      &::after {
        content: "\f107";
        font-family: 'fontawesome';
        border-top: 0;
        color: rgba($white, .4);
        border: 0;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
      }
    }
  }
}

// Nav Components
.nav-components {
  flex-direction: column;
  .nav-item {
    .nav-link {
      color: variables.$gray-color;
      border-radius: .8rem;
      padding-top: 0.63rem;
      padding-bottom: 0.63rem;
      &:hover {
        color: variables.$primary;
      }
      &.active {
        color: variables.$primary;
      }
    }
  }
}

/* Navbar btn */
.navbar-btn {
  position: relative;
  margin-left: 30px;
  margin-right: 30px;
  padding-top: 15px;
  .btn {
    padding: 9px 19px;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    transition: all .3s;
    &:hover {
      opacity: .7;
    }
    @include media-breakpoint-up(md) {
      font-size: 10px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 14px;
      // padding: 12px 32px;
    }
  }
  @include media-breakpoint-up(md) {
    padding-top: 28px;
    margin-left: 7px;
    margin-right: 0;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 30px;
    // margin-left: 15px;
  }
}

/* Navbar Dark Light */
.navbar-dark-light {
  background-color: variables.$gray-dark;
  box-shadow: 0 3px 10px rgba(0,0,0,.1);
  @include media-breakpoint-up(md) {
    box-shadow: none;
  }
   .nav-item.dropdown {
    .nav-link {
      @include media-breakpoint-up(md) {
        color: variables.$off-white;
      }
    }
  }
}