@use 'variables';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

/* Pagination */
.pagination {
  margin-bottom: 0;
  .page-item {
    &:first-child,
    &:last-child {
      .page-link {
        border: 1px solid #dedede;
        margin: 0;
        @include media-breakpoint-up(md) {
          padding: 0.5rem 1.3rem;
        }
        &:hover {
          color:  $white;
          background-color: variables.$primary;
          border-color: variables.$primary;
        }
      }
    }
    .page-link {
      color: variables.$pagination-color;
      background-color: variables.$pagination-bg;
      border: variables.$pagination-hover-border-color;
      border-radius: variables.$pagination-border-radius;
      text-transform: uppercase;
      transition: all 0.3s;
      font-size: 10px;
      font-weight: 500;
        @include media-breakpoint-up(md) {
        font-size: variables.$pagination-font-size;
        font-weight: variables.$pagination-font-weight;
        margin: 0 1px;
      }
      &:hover {
        color: variables.$pagination-hover-color;
      }
      &.active {
        color: variables.$pagination-active-color;
        cursor: default;
      }
    }
  }
}