@import '../../website_theme/scss/variables';


.bg-backoffice {
  background-color: $backoffice !important;
}

.bg-backoffice-light {
  background-color: $backoffice-light !important;
}

.text-backoffice {
  background-color: $backoffice-light !important;
  color: $black !important;
  font-weight: 500 !important;
  padding: 2px 0 2px 0 !important;
}

