$starColour:      #fec701;
$starHoverColour: #ffdb5e;
$starGrayColour:  #cecece;

.star-rating {
  font-family: 'Font Awesome 5 Free';

  > .stars {
    border: none;
    display: inline-block;

    &:not(:checked) {
      > input {
        position: absolute;
        clip: rect(0,0,0,0);
      }

      > label {
        float: right;
        width: 1.1em;
        //padding: 0 .05em;
        margin-right: 0.35rem;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        font-size: 1.25125rem;
        color: $starGrayColour;

        &:before {
          content: '\f005  ';
          font-weight: 200;
        }

        &:hover,
        &:hover ~ label {
          color: $starHoverColour;
          text-shadow: 0 0 3px $starHoverColour;
          &:before {
            content: '\f005  ';
            font-weight: 900;
          }
        }
      }
    }

    > input:checked {
      & ~ label {
        &:before {
          content: '\f005  ';
          font-weight: 900;
          color: $starColour;

        }
      }
    }

    > label:active {
      position: relative;
      top: 2px;
      color: $starColour;

    }
  }
}

.star-rating-passiv {
  overflow: hidden;
  display: inline-block;
  position: relative;
  //height: 19px;
}

.star-rating-passiv-lg {
  font-size: 1.25125rem;
}

.star-rating-passiv .stars-active {
  color: $starColour;
  position: relative;
  z-index: 10;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}

.star-rating-passiv .stars-inactive {
  color: $starGrayColour;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-text-stroke: initial;
  /* overflow: hidden; */
}