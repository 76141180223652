@use 'variables';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

/* Owl Carousel */
.owl-theme {
  .owl-dots {
    margin-top: 0 !important;
    .owl-dot {
      span {
          background-color: transparent;
          border: 1px solid  $white;
          width: 12px;
          height: 12px;
          margin: 0 5px;
        }
      &.active {
        span {
          background-color: variables.$primary;
          border: 1px solid variables.$primary;
        }
      }
      &:hover {
        span {
          background-color: variables.$primary;
          border: 1px solid variables.$primary;
        }
      }
      &:focus {
        outline: 0;
      }
    }
  }
}

.package-slider {
  .owl-stage-outer {
    border-radius: 5px;
  }
  &.owl-theme {
    .owl-nav {
      margin-top: 0;
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
      .owl-prev,
      .owl-next {
        position: absolute;
        top: 100%;
      }
      .owl-prev {
        left: 20px;
      }
      .owl-next {
        right: 20px;
      }
    }
    .owl-nav [class*=owl-] {
      margin-top: 0;
      font-size: 1.25rem;
      color:  $white;
      background-color: rgba(variables.$black, .35);
      border-radius: 4px;
      width: 75px;
      height: 75px;
      line-height: 75px;
      margin: 0;
      top: 50%;
      outline: 0;
      transform: translateY(-50%);
      transition: all 0.3s;
      &:hover {
        background-color: rgba(variables.$black, .7);
      }
    }
  }
  img {
    object-fit: cover;
    height: 300px;
    transition: all .4s;
    @include media-breakpoint-up(md) {
      height: auto;
    }
  }
  &.package-single {
    img {
      @include media-breakpoint-up(xl) {
        height: 450px !important;
      }
      @include media-breakpoint-up(md) {
        height: 400px;
        object-fit: fill;
      }
    }
  }
}

/* Gallery Photo Slider */
.gallery-photo-slider {
  .owl-stage-outer {
    border-radius: 5px;
  }
  &.owl-theme {
    .owl-nav {
      margin-top: 0;
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
      .owl-prev,
      .owl-next {
        position: absolute;
        top: 100%;
      }
      .owl-prev {
        left: 20px;
      }
      .owl-next {
        right: 20px;
      }
    }
    .owl-nav [class*=owl-] {
      margin-top: 0;
      font-size: 1.25rem;
      color:  $white;
      background-color: rgba( $white, .35);
      border-radius: 4px;
      width: 75px;
      height: 75px;
      line-height: 75px;
      margin: 0;
      top: 50%;
      outline: 0;
      transform: translateY(-50%);
      transition: all 0.3s;
      &:hover {
        background-color: rgba( $white, .5);
      }
    }
  }
  img {
    object-fit: cover;
    height: 300px;
    transition: all .4s;
    @include media-breakpoint-up(md) {
      height: auto;
    }
  }
}

.owl-slider-tabs {
  bottom: 30px;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
  li {
    background-color:  $white;
    border: 2px solid $white;
    border-radius: 5px;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
    img {
      height: auto;
    }
  }
}

.slidetabs {
  opacity: .5;
  &.active {
    opacity: 1;
  }
}