@import url('../shared/rotate.css');

.nav-menuzord .menuzord .menuzord-menu .indicator i {
    display: none;
}

.cc-revoke {
    display: none;
}

.cc-invisible {
    display: none;
}

label:not(.custom-control-label).required::after {
    margin-left: 1px;
    content: '*';
}

.flash-message {
    z-index: 1001;
    top: 80px;
}

.fit-col {
    white-space: nowrap;
    width: 1%;
}

.ellipsed {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ellipsed-td {
    max-width: 0;
    width: 100%;
}

.ellipsed-3 {
    display: -webkit-box;
    overflow : hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.ellipsed-2 {
    display: -webkit-box;
    overflow : hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/** BreakCrumb **/
.breadcrumb-container {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
}
.breadcrumb{
    background-color: transparent;
}
ol.breadcrumb {
    margin-bottom: 0;
}
.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "\2022";
}
.breadcrumb-item:not(.active) {
    color: #6c757d;
}
.breadcrumb-item.active {
    color: unset;
}

/** Yacht-Slider **/
.hidden-not-initialized:not(.slick-initialized) {
    visibility: hidden;
    height: 0;
}
.slick-initialized {
    visibility: visible !important;
    height: auto;
}
.slick-initialized .placeholder {
    visibility: hidden;
    height: 0;
}

.dropdown-menu {
    z-index: 1050 !important;
}

.buttons-fixed-bottom {
    padding-bottom: .7rem !important;
    padding-top: .6rem !important;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    display: -ms-flexbox !important;
    display: flex !important;
    background-color: #f4f4f4;
}

.opacity-3 {
    opacity: .3;
}

.line-height-15 {
    line-height: 1rem;
}

.tooltip-inner {
    background-color: #f0f0f0;
    color: black;
    border: 1px solid #c1c1c1;
}

.youtube-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
}

.youtube-container > iframe,
.youtube-container > object,
.youtube-container > embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
    -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
    outline: none !important;
}

div[aria-expanded="true"] .flip-collapse-icon {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.popover {
    background-color: #dfe3d9 !important;
    border: 1px solid #8f8f8f !important;
    box-shadow: 7px 10px #a4bf8117;
}

.popover-body {
    font-size: 0.85rem !important;
}

.popover-header:not(.gold) {
    background-color: #a2bf85 !important;
    color: #303030 !important;
    font-weight: 500 !important;
}

.popover-header.gold {
    background: radial-gradient(ellipse at top left, white, gold);
    color: #303030 !important;
    font-weight: 500 !important;
}

a.blue-link {
    color: #1e70cd;
}

a:hover.blue-link {
    color: #3c8ae2;
}

.speech-bubble {
    position: relative;
    background: #dddddd;
    border-radius: .7em;
    border: 1px solid #dddddd;
}

.speech-bubble:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-right-color: #dddddd;
    border-left: 0;
    margin-top: -5px;
    margin-left: -5px;
}

.speech-bubble-top:after {
    top: 14px !important;
}

a.text-primary-dark {
    color: #516532 !important;
}
a:hover.text-primary-dark {
    color: #82a04f !important;
}

.breadcrumb-item a {
    font-weight: 500;
    color: #516532;
}

.breadcrumb-item a:hover {
    font-weight: 500;
    color: #82a04f;
}

.btn-outline-primary {
    color: #516532;
    font-weight: 500;
}

.line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.select-holidays .selectric-items {
    margin-bottom: 64px !important;
}

.form-custom-sm input, .form-custom-sm select {
    height: calc(1.5em + 0.8rem + 2px);
    padding: 0.4rem 0.7rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.form-custom-sm label {
    margin-bottom: 2px;
    font-size: 0.875rem;
}

.bg-gray {
    background-color: #e7e7e7;
}

.mw-popup {
    max-width: 300px;
}

.lead {
    line-height: 24px !important;
}

.read-more-4 {
    --read-more-line-clamp: 4;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--read-more-line-clamp, 4);
}

.nav-small li .nav-link {
    padding: 10px 10px !important;
    background-color: #e5f0d8 !important;
    margin-right: 0.15rem !important;

}

.nav-small .nav-item {
    margin-right: 0.2rem !important;
}

.nav-small li .nav-link.active {
    padding: 10px 15px !important;
    background-color: #fff !important;
}

.nav-small li .nav-link:not(.active) {
    box-shadow: 5px -57px 6px -58px rgba(0,0,0,0.2) inset;
}
