/* Wird in Backoffice und Website verwendet */
@use 'colors' as colors;

.bg-va {
  background-color: colors.$color-va;
}

.bg-va-light {
  background-color: colors.$color-va-light;
}

.bg-va-lighter {
  background-color: colors.$color-va-lighter;
}

.bg-va-dark {
  background-color: colors.$color-va-dark;
}

.bg-primary-light {
  background-color: colors.$color-primary-light !important;
}

.text-adr {
  color: colors.$color-adr !important;
}

.bg-adr {
  background-color: colors.$color-adr !important;
}

.bg-adr-light {
  background-color: colors.$color-adr-light !important;
}

.bg-angebot-vorgang {
  background-color: colors.$color-angebot-vorgang !important;
}

.bg-angebot-vorgang-light {
  background-color: colors.$color-angebot-vorgang-light !important;
}

.bg-angebot-vorgang-dark {
  background-color: colors.$color-angebot-vorgang-dark !important;
}

.text-angebot {
  color: colors.$color-angebot !important;
}

.bg-angebot {
  background-color: colors.$color-angebot !important;
}

.bg-angebot-light {
  background-color: colors.$color-angebot-light !important;
}

.border-angebot {
  border-color: colors.$color-angebot !important;
}

.text-vertrag {
  color: colors.$color-vertrag !important;
}

.bg-vertrag {
  background-color: colors.$color-vertrag !important;
}

.bg-vertrag-light {
  background-color: colors.$color-vertrag-light !important;
}

.bg-vertrag-lighter {
  background-color: colors.$color-vertrag-lighter !important;
}

.border-vertrag {
  border-color: colors.$color-vertrag !important;
}

.text-new-import {
  color: colors.$color-new-import-dark !important;
}

.bg-new-import {
  background-color: colors.$color-new-import !important;
}

.bg-new-import-light {
  background-color: colors.$color-new-import-light !important;
}

.text-veranstalter {
  color: colors.$color-veranstalter-dark !important;
}

.bg-veranstalter {
  background-color: colors.$color-veranstalter-dark !important;
}

.text-todo {
  color: colors.$color-todo !important;
}

.bg-todo {
  background-color: colors.$color-todo !important;
}

.bg-todo-light {
  background-color: colors.$color-todo-light !important;
}

.bg-todo-lighter {
  background-color: colors.$color-todo-lighter !important;
}

.text-ticket {
  color: colors.$color-ticket !important;
}

.bg-ticket {
  background-color: colors.$color-ticket !important;
}

.bg-ticket-light {
  background-color: colors.$color-ticket-light !important;
}

.bg-wiedervorlage {
  background-color: colors.$color-wiedervorlage !important;
}

.bg-wiedervorlage-light {
  background-color: colors.$color-wiedervorlage-light !important;
}

.bg-off-white {
  background-color: colors.$color-off-white !important;
}

.bg-light-gray {
  background-color: colors.$color-light-gray !important;
}

.w-33 {
  width: 33% !important;
}

.w-66 {
  width: 66% !important;
}

.line-through {
  text-decoration: line-through;
}

.line-through.line-through-red {
  text-decoration-color: RED;
}

.cursor-help {
  cursor: help;
}

.cursor-copy {
  cursor: copy;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.text-dark-blue {
  color: colors.$color-dark-blue;
}

.text-service-call {
  color: colors.$color-service-call;
}

.bg-bluish {
  background-color: colors.$color-bluish;
}

.bg-bluish-transparent {
   background-color: colors.$color-bluish-transparent;
 }

.bg-blue {
  background-color: colors.$blue;
}

.bg-blue-light {
  background-color: colors.$blue-light;
}

.bg-blue-lighter {
  background-color: colors.$blue-lighter;
}

.bg-mail-in {
  background-color: #fdf5f5 !important;
}
.bg-mail-out {
  background-color: #f0fdfd !important;
}

.bg-tt-super-danger {
  background-color: colors.$color-tt-super-danger;
}

.bg-rekla {
  background-color: colors.$color-rekla;
}

.bg-rekla-light {
  background-color: colors.$color-rekla-light;
}

.text-rekla {
  color: colors.$color-rekla;
}

.bg-marker-green {
  background-color: colors.$color-marker-green;
}

.bg-marker-yellow {
  background-color: colors.$color-marker-yellow;
}

.bg-option {
  background-color: colors.$color-option;
}

.bg-option-light {
  background-color: colors.$color-option-light;
}

.marker-green {
  padding: 0.25rem;
  background-color: colors.$color-marker-green;
  border-radius: 0.25rem;
}

.marker-yellow {
  padding: 0.25rem;
  background-color: colors.$color-marker-yellow;
  border-radius: 0.25rem;
}

.text-primary-dark {
  color: colors.$color-primary-dark !important;
}

.bg-danger-light {
  background-color: #f9dbdb;
}

.text-muted-1 {
  color: #878787;
}

.text-muted-2 {
  color: #bebebe;
}
