@import '../../website_theme/scss/variables';

.ribbon {
  position: absolute;
  right: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.ribbon-left {
  left: -5px; top: -5px;
  text-align: left;
}
.ribbon span {
  font-size: 14px;
  font-weight: bold;
  color: $light-gray;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: $dark-red;
  /*background: linear-gradient(#B6BAC9 0%, #808080 100%);*/
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; right: -21px;
}
.ribbon-left span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  top: 19px; left: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #808080;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #808080;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #808080;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #808080;
}