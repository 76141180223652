$color-medium-dark:       #484848;

$color-primary:           #a6bf7d;
$color-primary-dark:      #698242;
$color-primary-light:     #e5f0d8;

$color-adr:               #dce6cc;
$color-adr-light:         #ecf2e4;

$color-angebot-vorgang: #ffd9b0;
$color-angebot-vorgang-light: #fae4d2;
$color-angebot-vorgang-dark: #ffc889;

$color-angebot:           #f7f0a0;
$color-angebot-light:     #fcf9de;
$color-angebot-dark:      #ff9000;

$color-vertrag:           #93ccff;
$color-vertrag-light:     #d1eaff;
$color-vertrag-lighter:   #f3f9ff;
$color-vertrag-dark:      #007cff;

$color-tt-super-danger:   #552583;
$color-rekla:             #c7a1dd;
$color-rekla-light:       #f4ecff;

$color-marker-green:      #50ff59;
$color-marker-yellow:     #f8ff00;

//$color-va:                #ccae9f;
//$color-va-light:          #dac9c0;
//$color-va-lighter:        #e9e1dd;
//$color-va-dark:           #b09486;

/*$color-va:                #9ad0d6;
$color-va-light:     #bfdee1;
$color-va-lighter:   #d8e9ea;
$color-va-dark:      #6cccd7;*/

$color-va:                #8cc9d0;
$color-va-light:          #bfdee1;
$color-va-lighter:        #d8eef1;
$color-va-dark:           #8cc9d0;

$color-new-import:        #95d2ce;
$color-new-import-light:  #bffffc80;
$color-new-import-dark:   #65a9a4;

$color-veranstalter-dark: #54a0ff;

$color-todo:              #e6d5f5;
$color-todo-light:        #f0e7f8;
$color-todo-lighter:      #f3f0f5;
$color-todo-dark:         #c6b3d6;

$color-ticket:            #ffbebe;
$color-ticket-light:      #fff3f3;
$color-ticket-dark:       #d69e9e;

$color-wiedervorlage:     #89fb8d;
$color-wiedervorlage-light: #d9ffda;
$color-wiedervorlage-dark: #39ff3e;

$color-dark-blue:         #011171;

$color-service-call:         #00c304;

$color-bluish:            #dafffd;
$color-bluish-transparent: #dafffd70;

$color-off-white:         #dedede;
$color-light-gray:        #eee;

$blue:                    #5C80BC;
$blue-light:              #99b2da;
$blue-lighter:            #e9f1ff;

$color-option:            #56aaae;
$color-option-dark:       #489093;
$color-option-light:      #a2d7d8;

$color-sc-1:              #fff5bf;
$color-sc-1-alt:          #bbe8f9;
$color-sc-2:              #e3ffd7;
$color-sc-3:              #fcacae;
$color-sc-4:              #c1f4b5;