@use 'variables';

@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

/* Footer Background Color */
.footer, .footer-2 {
  .footer-bg-color {
    background-color: variables.$off-white;
  }
  .title-tag {
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
    h6 {
      color: variables.$gray-dark;
      font-size: 15px;
    }
  }
  .list-unstyled {
    .media {
      i {
        color: variables.$gray-dark;
      }
      .media-body {
        a {
          color: variables.$gray-dark;
          font-size: 0.875rem;
          transition: all .3s;
          &:hover {
            color: variables.$primary;
          }
        }
      }
    }
  }
  .gallery {
   > .col-4 {
      padding: 0 0 0 15px;
    }
  }
  .copyright {
    border-top: 1px solid variables.$gray-color;
    background-color: variables.$off-white;
    p {
      color: variables.$gray-dark;
      font-size: 13px;
      text-align: center;
      @include media-breakpoint-up(lg) {
        font-size: 14px;
      }
    }
    .list-inline {
      a {
        font-size: 13px;
        color: variables.$gray-dark;
        transition: all .3s;
        @include media-breakpoint-up(lg) {
          font-size: 14px;
        }
        &:hover {
          color: variables.$primary;
        }
      }
    }
  }
}

.footer-2 {
  h6 {
    font-weight: 400;
  }
}
